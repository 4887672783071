import React, { Component } from 'react';
import api from './helpers/apiClient';
import Loader from './components/Loader'
import moment from 'moment';
import RedeemGiftcardModal from './components/RedeemGiftcardModal'
import {QRCodeSVG} from 'qrcode.react';
var Barcode = require('react-barcode');

class BrandedGiftcard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      youtube: false,
      showConfirmRedeemModal: false,
      redeeming: false,
      redeemError: ""
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.loadGiftcard(match.params.brand, match.params.shortcode)
  }

  loadGiftcard = (brand, shortcode) => {

    this.setState({
      loading: true
    });

    //base64 decode shortcode
    let queryString = atob(shortcode);

    //part queryString
    let parts = queryString.split('&');

    let { code, denom, expiry, code_format } = parts.reduce((acc, part) => {
      let [key, value] = part.split('=');
      acc[key] = value;
      return acc;
    }, {});


    const baseDate = moment('1899-12-30');
    expiry = baseDate.add(expiry, 'days');

    this.setState({
      loading: false,
      brand,
      item: {
        code,
        denom,
        expiry,
        codeFormat: code_format
      }
    });
  }

  handleOnClickRedeem = () => {
    this.setState({ showConfirmRedeemModal: true });
  }

  handleOnCloseRedeemModal = () => {
    this.setState({ showConfirmRedeemModal: false, redeeming: false });
  }

  handleRedeem = () => {
    this.setState({
      redeeming: true
    });

    const { match } = this.props;

    return api(`/giftcard/${match.params.shortcode}/redeem`, {
      method: 'GET'
    }).then((res) => {

      this.setState({
        redeeming: false
      });

      if (res.success) {
        //sleep for 3 seconds
        setTimeout(() => {
          this.loadGiftcard(match.params.shortcode)
        }, 3000);
        this.setState({ showConfirmRedeemModal: false });
      }
    })
  }

  renderImage = (imageSrc, name) => {

    return (<img className="barcode" alt={name} src={imageSrc}/>)
  }

  renderValue = () => {
    const { item } = this.state;

    if (item.new_value_format) {
      return <span id="preview-variant-title">{item.new_value}</span>
    } else if (item.value) {
      return <span id="preview-variant-title">RM{typeof item.value === 'number' ? item.value.toFixed() : item.value}</span>
    }
  }

  render(){

    const { loading, item, brand, showConfirmRedeemModal, redeeming, redeemError } = this.state;

    return (
      <section id="giftcard">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="giftcard-preview-contianer mt-3">
                {
                  loading && <Loader/>
                }
                {
                  item && !loading &&
                    <div>
                      {
                        (brand === 'a'  || brand === 'switch') &&
                        this.renderImage("/assets/img/switch-banner.png", "switch banner")
                      }
                      {
                        (brand === 'b' || brand === 'aeon') &&
                        this.renderImage("/assets/img/aeon-banner.png", "aeon banner")
                      }
                      {
                        brand === 'lotuss' &&
                        this.renderImage("/assets/img/lotus-banner.png", "lotuss banner")
                      }
                      <div className="preview-content-contianer">
                        <p className="switch-title">Here’s your e-Gift voucher <br/> worth RM {item.denom}.</p>
                        <div id="preview-code-expiry">
                          <p>T&C stated below</p>
                          <p></p>
                          <p className="switch-code">Your e-Gift Voucher code<br/>
                          </p>
                          <Barcode className="barcode" value={item.code} format={item.codeFormat}/>
                          <QRCodeSVG value={item.code} />
                          <p className="switch-code expiry-date">Expiry date:<br/>
                            <p id="preview-expiry">{moment(item.expiry).format('Do MMMM YYYY')}</p>
                          </p>
                        </div>
                        <hr />
                        {
                          (brand === 'a' || brand=== 'switch' )&&
                            <div className="preview-validity">Terms and Conditions: <br/>
                              This voucher is only valid for one time use.<br/>
                              This voucher can only be used at Switch online store, Switch outlets and Urban Republic outlets.<br/>
                              This voucher is not redeemable for cash in whole or in part.<br/>
                            This voucher will be forfeited after the expiry date.<br/>
                            This voucher can be used in conjunction with all promotions, sales or discounted oers at any Switch and Urban Republic outlets.<br/>
                            Photocopies of this voucher will not be accepted.<br/>
                            Safe keeping of this voucher is the responsibility of the holder.<br/>
                            CG Computers Sdn Bhd shall not be responsible in any way of the loss or damage of this voucher and no replacement(s) whatsoever will be entertained.<br/>
                            The holder must surrender this voucher upon use.<br/>
                            If the amount of the purchase exceeds the value of this voucher, the balance will be paid for in cash or card.<br/>
                            If the amount of the purchase is less than the value of this voucher, no refund of the balance amount will be given.<br/>
                            CG Computers Sdn Bhd reserves the right to change, modify, or amend the terms and conditions without any prior notice.<br/>
                            Cash voucher is valid upon activation of the unique code by CG Computers Sdn Bhd.</div>
                        }
                        {
                          (brand === 'b' || brand === 'aeon') &&
                          <div className="preview-validity">Terms and Conditions: <br/>
                            1. The value of this e-Gift Voucher stated herein is in Malaysian Ringgit.<br/>
                            2. This e-Gift Voucher shall be valid for as stipulated in the e-Gift Voucher. AEON CO.(M) BHD. [198404014370 (126926-H)] (“hereinafter referred to as “AEON”) shall not entertain any request for any extension, refund or replacement for any expired e-Gift Voucher.<br/>
                            3. This e-Gift Voucher allows the purchaser/holder/bearer of the e-Gift Voucher (“Holder”) to redeem goods at any physical general merchandise store of AEON excluding MaxValu/MaxValu Prime, DAISO, AEON Wellness and Komai-so (“Physical Store”). This e-Gift Voucher is ONLY applicable in Malaysia. This e-Gift Voucher shall not be applicable for any online purchase/redemption at any online platforms or applications of AEON.<br/>
                            4. This e-Gift Voucher is non-refundable, non-transferable and cannot be exchanged for cash, other type of vouchers or any other forms of legal tender.<br/>
                            5. The use and care of the e-Gift Voucher shall be at the absolute responsibility of the Holder. AEON shall not be liable to the Holder in the event the e-Gift Voucher is invalidated for any reasons, including but not limited to unauthorized duplication/download.<br/>
                            6. In order to redeem the e-Gift Voucher, the Holder shall be required to present the valid e-Gift Voucher barcode for scanning, for redemption at any Physical Store of AEON.<br/>
                            7. Each e-Gift Voucher shall be valid for one-time redemption ONLY. During the redemption of goods, if the value of the goods exceeds the amount stated in the e-Gift Voucher, the difference thereof shall be paid by the Holder. However, if the value of the goods is less than the amount stated in the e-Gift Voucher, then the difference thereof shall not be refunded to the Holder.<br/>
                            8. For any purchase of the e-Gift Voucher, the Holder shall not be entitled to claim any points under the AEON Member Plus Visa Card.<br/>
                            9. Any redemption of e-Gift Voucher shall be subject to verification by AEON. AEON reserves the right to refuse or reject any e-Gift Voucher that has been tampered with or found in any way unacceptable for redemption by AEON.<br/>
                            10. AEON shall have the sole and absolute right to substitute the e-Gift Voucher with any other items as determined by AEON at any time without prior notice to the Holder.<br/>
                            11. Any loss of e-Gift Voucher shall not be replaced by AEON. AEON shall not be held responsible and does not compensate the Holder for loss of e-Gift Voucher due to any reasons including but not limited to technical error in the application and/or malfunction or loss of equipment.<br/>
                            12. The invalidity, illegality or unenforceability of any terms and conditions hereunder shall not affect or impair the continuation in enforcement of the remainder of the terms and conditions contained herein.<br/>
                            13. The terms and conditions contained herein shall be governed and constructed in accordance with the laws of Malaysia.<br/>
                            14. AEON shall not in any way, be responsible and liable for any losses, damages or expenses incurred or suffered by the Holder in relation to the e-Gift Voucher.<br/>
                            15. AEON reserves the right to change, vary and/or amend any of the terms and conditions contained herein without any prior notice to the Holder.<br/>
                            16. AEON’s decision for any matter in relation to the e-Gift Voucher shall be final and binding.<br/>
                            17. By purchasing this e-Gift Voucher, the Holder agrees and acknowledges that the Holder is bound by all terms and conditions herein.<br/>
                            18. The personal data is collected and processed in accordance to AEON privacy policy at https://www.aeonretail.com.my/privacypolicy/, which may be updated and/or modified by AEON from time to time.<br/>
                          </div>
                        }
                        {
                          brand === 'lotuss' &&
                          <div className="preview-validity">Terms and Conditions: <br/>
                          1. Lotus’s Cash Voucher, Lotus’s eVoucher & Lotus’s Shop Online eVoucher are valid for 365 days from the date of issuance or until a pre-determined fixed expiration date.<br/>
                          2. Lotus’s Cash Voucher and Lotus’s eVoucher can be used in place of equivalent cash value to buy merchandise at any of our stores in Malaysia.<br/>
                          3. Lotus’s Cash Voucher and Lotus’s eVoucher can be used together with cash (legal tender), credit / debit card or e-wallets.<br/>
                          4. Maximum redemption limit of RM500 per transaction is applicable for multiple Lotus’s Cash Voucher and Lotus’s eVoucher redemptions only.<br/>
                          5. Strictly no validity date extension provided for any vouchers purchased from Lotus’s Malaysia.<br/>
                          6. No minimum amount requirement to spend for Lotus’s Cash Voucher.  <br/>
                          7. No change will be given if the purchase value is less than the value of Lotus’s Cash Voucher denomination.<br/>
                          8. Lotus’s eVoucher, customer’s minimum amount spend must be at least the same as Lotus’s eVoucher denomination. <br/>
                          9. Our vouchers are non-exchangeable nor refundable (ie not returnable for cash or new voucher).<br/>
                          10. Lotus’s Malaysia will not entertain any claims for defaced, damaged or lost vouchers.<br/>
                          11. All vouchers are not valid at the Food Court or any outlets not operated by Lotus’s Malaysia.<br/>
                          12. Lotus’s Malaysia reserves the right to change, modify, add, or remove portions of Terms and Conditions of use at any time. Changes will be effective with no other notice provided.<br/>
                          13. Your use of any vouchers from Lotus’s Malaysia indicates your agreement to be bound by Lotus’s Malaysia Terms and Conditions.<br/>
                          14. Lotus’s Malaysia reserves the right to withdraw or cancel any vouchers for any reason at any time.<br/>
                          15. Lotus’s Malaysia shall not be liable to any customer or household for any financial loss arising out of the refusal, cancellation, or withdrawal of any vouchers or any failure or inability of a customer or household to use a voucher for any reasons.<br/>
                          16. When you use a Lotus’s eVoucher you warrant to Lotus’s Malaysia that you are the duly authorised recipient of the voucher and that you are using it in good faith.<br/>
                          17. If you redeem, attempt to redeem, or encourage the redemption of the vouchers to obtain discounts to which you or a third party are not entitled you may be committing a civil or criminal offence.<br/>
                          18. If we reasonably believe that any vouchers are being used unlawfully or illegally we may reject or cancel any vouchers and you agree that you will have no claim against us in respect of any rejection or cancellation. Lotus’s Malaysia reserves the right to take any further action it deems appropriate in such instances. <br/>
                          19. Each Voucher is valid for one-time redemption only.<br/>
                          </div>
                        }
                        <h5 id="gifting-made-easy" >- Gifting Made Easy -</h5>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        {
          showConfirmRedeemModal &&
          <RedeemGiftcardModal redeeming={redeeming} redeemError={redeemError} onClickSave={this.handleRedeem} onClose={this.handleOnCloseRedeemModal} title="Redeem"/>
        }
      </section>
    )
  }
}

export default BrandedGiftcard;
